// Fonts
@font-face {
    font-family: 'crimson_textroman';
    src: url('/assets/fonts/crimsontext-roman-webfont.eot');
    src: url('/assets/fonts/crimsontext-roman-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/crimsontext-roman-webfont.woff2') format('woff2'),
         url('/assets/fonts/crimsontext-roman-webfont.woff') format('woff'),
         url('/assets/fonts/crimsontext-roman-webfont.ttf') format('truetype'),
         url('/assets/fonts/crimsontext-roman-webfont.svg#crimson_textroman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'UniversLTStd65Bold';
    src: url('/assets/fonts/universltstdbold.woff2') format('woff2'),
         url('/assets/fonts/universltstdbold.woff') format('woff'),
         url('/assets/fonts/universltstdbold.ttf') format('truetype'),
         url('/assets/fonts/universltstdbold.svg#UniversLTStd65Bold') format('svg');
}

@font-face {
    font-family: 'crimson_textsemibolditalic';
    src: url('/assets/fonts/crimsontext-semibolditalic-webfont.eot');
    src: url('/assets/fonts/crimsontext-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/crimsontext-semibolditalic-webfont.woff2') format('woff2'),
         url('/assets/fonts/crimsontext-semibolditalic-webfont.woff') format('woff'),
         url('/assets/fonts/crimsontext-semibolditalic-webfont.ttf') format('truetype'),
         url('/assets/fonts/crimsontext-semibolditalic-webfont.svg#crimson_textsemibolditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:url('/assets/fonts/icomoon.eot');
    src:url('/assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
        url('/assets/fonts/icomoon.woff') format('woff'),
        url('/assets/fonts/icomoon.ttf') format('truetype'),
        url('/assets/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
    content: "\e603";
}
.icon-list:before {
    content: "\e601";
}
.icon-arrow-down:before {
    content: "\e602";
}
.icon-uniE600:before {
    content: "\e600";
}
