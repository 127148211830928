// Variables

//-----Colors
$colorBlack:		#000000;
$colorWhite:		#ffffff;
$colorBiege:		#f6f2ec;
$colorBlue:			#779dbc;
$colorYellow:		#e8b228;
$colorGreen:		#67946d;
$colorGray:			#454545;



//-----Fonts
$fontRoman:				'crimson_textroman', sans-serif;
$fontUniverseBold:		'UniversLTStd65Bold', sans-serif;
$fontSemiBold:			'crimson_textsemibolditalic', sans-serif;
$fontIcon:				'icomoon';
