html {
	//@extend %proxima-nova;
	//color: #efe7cb;
	font-size: 100%;
}

*, *:before, *:after {
	//margin: 0;
	//padding: 0;
	box-sizing: border-box;
}

a img {
	// Because IE made a bad choice a long time ago.
	border: none;
}
