// Homepage
body{
	overflow: hidden;
	max-width: 100%;
}

body.open{
}

.slogan{
	margin: 0 auto;

	img{
		max-width: 100%;
		display: block;
		margin: 0 auto;
	}
}

.content-block{
	max-width: 100%;
	margin: 0 auto;
	min-width: 100%;

	h1{
		font-family: $fontRoman;
		font-size: 40px;
		margin: 0;
		padding: 0;
		font-weight: normal;
		line-height: 120%;
	}

	h2{
		font-family: $fontRoman;
		font-size: 40px;
		margin: 0;
		padding: 0;
		font-weight: normal;
		line-height: 120%;
	}

	p{
		font-family: $fontRoman;
		font-size: 18px;
		line-height: 125%;
		padding: 0;
	}

	ul{
		margin: 0;
		padding: 0;

		li{
			list-style-type: none;
			font-family: $fontRoman;
			line-height: 100%;
			font-size: 18px;
			padding: 10px 0;
		}
	}
}

.next-slide, .previous-slide{
	display: none;

	&.first{
		position: absolute;
		display: block;
		width: 100%;
		text-align: center;
		bottom: 0;
		left: 0;
		color: $colorWhite;
		z-index: 25;

		span{
			&.icon-arrow-down{
				display: block;
				text-shadow: 0 0 4px $colorGray;
				font-size: 30px;
				font-weight: 100;
				color: $colorWhite;
			}
			&.scroll-click{
				font-family: $fontSemiBold;
				color: $colorWhite;
			}
		}
	}
}

a{
	color: $colorWhite;

	.next-slide .first{
		position: absolute;
		display: block;
		width: 150px;
		text-align: center;
		bottom: 25px;
		left: 0;
		right: 0;
		color: $colorWhite;
		z-index: 25;
		position: absolute;
		margin: 0 auto;

		span{
			&.icon-arrow-down{
				display: block;
				text-shadow: 0 0 4px $colorGray;
				font-size: 30px;
				font-weight: 100;
			}
			&.scroll-click{
				font-family: $fontSemiBold;
			}
		}
	}
}

.container{
	display: flex;
	align-items: center;
}

ul.contact-list{
	li{
		font-size: 18px;
	}
}

@include medium{
	.slogan{
		text-align: center;
		display: flex;
		align-items: center;

		img{
			width: auto;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	.next-slide, .previous-slide{
		position: absolute;
		display: block;
		width: 150px;
		text-align: center;
		left: 0;
		right: 0;
		margin: 0 auto;
		color: $colorWhite;
		z-index: 250;

		&.first{
			bottom: 25px;
			font-size: 18px;
		}

		span{
			&.icon-arrow-down{
				display: block;
				text-shadow: 0 0 4px $colorGray;
				color: $colorWhite !important;
				font-size: 30px;
				font-weight: 100;
			}
			&.scroll-click{
				font-family: $fontSemiBold;
				color: $colorWhite;
			}
		}
	}
	.next-slide{
		bottom: 25px;
	}
	.previous-slide{
		top: 75px;
		span{
			&.icon-arrow-down{
				transform: rotate(180deg);
			}
		}
	}

	ul.contact-list{
		li{
			font-size: 24px;
		}
	}
}