/**
*
* Global
*
**/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
button {
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body{
  overflow-x: hidden;
}

body {
  min-height: 100%;
  box-sizing: border-box;

  &.open{
    overflow: hidden;
  }

  @include baseline-grid();
}

a{
  text-decoration: none;
  transition: all 0.3s ease-in-out;

}

pre {
  background: $colorGrayBackground;
  padding: 13px;
}

.main-container {
  @include container(true);
}

.container {
  @include container(true);
}

.container-medium {
  @include medium {
    @include container(true);
  }
}

.container-small {
  @include small-only {
    @include container(true);
  }
}

.content {
  @include wide {
    margin-right: 25.9%; // took this from the grid output - using @extend inside media-query is deprecated, need to figure out a better way to do this.

    & pre {
      margin-right: -25.9%;
    }
  }
}
