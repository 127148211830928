// Header
header{
	position: fixed;
	top: 0;
	width: 100%;
	padding: 0;
	background: $colorBiege;
	transition: transform 0.3s ease-in-out;
	transform:translateZ(10px);
	z-index: 500000;
	max-height: 72px;
}

.app-bar-container{
	transform: translate(0, 0);
	transition: transform 0.3s ease-in-out;

	&.open{
		transform: translate(250px, 0);
		transition: all 0.3s ease-in-out;

		.menu{
			span{
				&.icon-list{
					&:before{
						content: "\e603";
					}
				}

			}
		}
	}

	.menu{
		position: relative;
		padding: 5px;
		width: 50px;
		height: 50px;
		background: none;
		border: none;
		top: -1px;
		left: 0px;
		margin-bottom: -5px;

		&:hover{
			cursor: pointer;
		}

		span{
			&.icon-list{
				font-size: 38px;
				display: block;
				color: $colorGray;
			}
		}
	}

	.logo{
		margin-left: -90px;
		position: absolute;
		top: 14px;
		left: 50%;
		padding: 0;
		width: 180px;
		background: url(/assets/images/mobile-logo.png) no-repeat;
		background-position: center;
		background-size: 95%;
		text-indent: -9999px;
		min-height: 19px;
	}
}

nav#main-navigation{
	background: $colorBiege;
	height: 10000%;
	position: fixed;
	top: 0;
	width: 250px;
	z-index: 500;
	transform: translate(-250px, 0);
	transition: transform 0.3s ease-in-out;

	&.open{
		box-shadow: 5px 0 8px -4px rgba(0,0,0,.7);
		transform: translate(0, 0);
		transition: transform 0.3s ease-in-out;

	}

	h4{
		display: block;
		width: 100%;
		margin: 0;
		padding: 11px 15px 9px;
		font-family: $fontUniverseBold;
		font-size: 20px;
		border-bottom: 2px solid $colorGray;
		background: $colorBlack;
		color: $colorWhite;
	}

	ul{
		padding: 0;
		margin: 0;
		display: block;
		width: 100%;
		font-family: $fontUniverseBold;
		text-transform: uppercase;
		font-size: 18px;

		li{
			list-style-type: none;
			display: block;
			border-bottom: 1px solid $colorGray;

			&.none{
				display: none;
				visibility: hidden;
				height: 0;
			}

			a{
				display: block;
				padding: 17px 15px 15px;
				color: $colorGray;

				&:hover{
					color: $colorBlack;
				}
			}
		}
	}
}

@include medium{
	header{}
	.app-bar-container{
		.menu{
			display: none;
		}
		.logo{
			margin-left: -100px;
			position: relative;
			top: 0;
			left: 50%;
			padding: 35px 0;
			width: 200px;
			height: 45px;
			background: url(/assets/images/desktop-logo.png) no-repeat;
			background-position: center;
			background-size: 99%;
			text-indent: -9999px;
			display: inline-block;
		}
	}
	nav#main-navigation{
		background: none;
		height: auto;
		position: fixed;
		top: 0;
		width: auto;
		z-index: 500;
		transform: translate(0, 0);

		h4{
			display: none;
		}

		ul{
			padding: 0;
			margin: 9px 5px 0;
			display: block;
			width: 100%;
			font-size: 15px;

			li{
				list-style-type: none;
				display: inline-block;
				border-bottom: none;
				padding: 0 5px 0;

				&.active{
					border-bottom: 4px solid $colorGray;
					transition: all 0.2s ease-in-out;
				}

				&:hover{
					border-bottom: 4px solid $colorGray;
					transition: all 0.2s ease-in-out;
				}

				a{
					padding: 17px 0 4px;
					border-bottom: 4px solid trasparent;
					transition: all 0.4s ease-in-out;
				}
			}
		}
	}
}

@include wide{
	header{}
	nav#main-navigation{
		ul{
			margin: 9px 10px 0;
			font-size: 15px;

			li{
				margin: 0 15px 0;
			}
		}
	}
}